@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "SofiaPro";
  src: url("/public/assets/fonts/Sofia\ Pro\ Font/Sofia Pro Regular Az.otf");
}
body {
  margin: 0;
  font-family: "SofiaPro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast--success {
  color: #fff !important;
  background: linear-gradient(#2061a8 0%, #0078dc 100%) !important;
  max-width: "650px";
  font-family: "SofiaPro";
  border-radius: 0;
  text-align: left;
  font-weight: 500;
}

.Toastify__toast--error {
  color: #fff !important;
  background: #e71515 !important;
  max-width: "650px";
  font-family: "SofiaPro";
  font-weight: 500;
  border-radius: 0;
  text-align: left;
}
